import styled from 'styled-components';
import Colours from '../../constants/colours';
import Typography from '../../constants/typography';

const Section = styled.section`
  text-align: center;
`;

export const Heading = styled.h1`
  color: ${Colours.white};
  font-size: ${Typography.title.desktop.fontSize};

  @media (max-width: 800px) {
    font-size: ${Typography.featureTitle.mobile.fontSize};
  }
`;

export const BlackSection = styled(Section)`
  background-color: ${Colours.black};
  background-image: url('/images/plans-premium-bg.png');
  background-size: cover;
  padding: 10rem 0;

  @media (max-width: 900px) {
    padding-bottom: 1rem;
  }
`;

export const PremiumWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  justify-items: center;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;

export const PremiumItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 250px;
  margin-top: 2rem;
  color: ${Colours.white};
`;

interface PremiumIconProps {
  width?: string;
}

export const PremiumIcon = styled.div<PremiumIconProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  width: ${(props) => props.width || '200px'};
  height: ${(props) => props.width || '200px'};
`;

export const PremiumHeader = styled.h3`
  font-size: ${Typography.subtitle.desktop.fontSize};
  line-height: ${Typography.subtitle.desktop.lineHeight};
  margin-top: 1rem;
`;

export const PremiumDetails = styled.div`
  font-size: ${Typography.legal.fontSize};
`;
