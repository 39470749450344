import React from 'react';
import styled, {createGlobalStyle} from 'styled-components';
import {PlanSelector} from '../../components/PlanSelector';
import {features} from '../../components/PremiumSection/features';
import {GlobalStyles} from '../../theme/GlobalStyles';
import {PlanBillingType} from '../../types';
import Colours from '../../constants/colours';
import {useState} from 'react';
import {useEffect} from 'react';
import {CatalogueApi} from '@focusrite-novation/ampify-api';

const Plans = () => {
  const [selectedPlan, setSelectedPlan] = useState<PlanBillingType>('monthly');
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [reason, setReason] = useState<string>('');

  const handleSwitchPlan = (plan: PlanBillingType) => setSelectedPlan(plan);

  const fetchImageUrls = async () => {
    try {
      const catalogueApi = new CatalogueApi();
      const packs = await catalogueApi.packList();
      const images = packs.data.packs.map((pack) => pack.imageUrl);
      setImageUrls(images);
    } catch (error) {
      console.error('Error fetching packs');
      console.error(error);
    }
  };

  useEffect(() => {
    fetchImageUrls();
  }, []);

  useEffect(() => {
    setReason(new URLSearchParams(window.location.search).get('reason'));
  }, []);

  const handleStartTrial = () =>
    window.location.assign(
      `/subscription/payment/?planId=${monthlyId}`
    );
  const handleLogin = () =>
    window.location.assign('https://ampifystudio.com/login');

  const handleClose = () =>
    window.location.assign('https://ampifystudio.com/close');

  return (
    <>
      <GlobalStyles />
      <PlansPageStyles />
      <Cross onClick={handleClose}>&times;</Cross>
      <Background>
        <ImageScroller
          forwards={true}
          imageUrls={imageUrls.slice(0, imageUrls.length / 2)}
        />
        <ImageScroller
          forwards={false}
          imageUrls={imageUrls.slice(imageUrls.length / 2)}
        />
        <ImageOverlay />
      </Background>
      <Content>
        <Titles>
          <Studio>Ampify Studio</Studio>
          <Premium>Premium</Premium>
        </Titles>
        <Subtitle>{reason}</Subtitle>

        <PremiumFeatures>
          {features().map((feature) => (
            <PremiumItem key={feature.headline}>
              {feature.icon('38px')}
              <FeatureDescription>
                {feature.shortDescription}
              </FeatureDescription>
            </PremiumItem>
          ))}
        </PremiumFeatures>

        <PlanSelectorContainer>
          <PlanSelector
            onSelectPlanType={handleSwitchPlan}
            pricing={{
              monthly: {price: '7.99', currencySymbol: '£', term: 'mo'}
            }}
          />
        </PlanSelectorContainer>

        <StartTrialButton onClick={handleStartTrial}>
          Start 30 day FREE trial
        </StartTrialButton>

        <AlreadySubscribedLink onClick={handleLogin}>
          Already a subscriber? <strong>Log in</strong>
        </AlreadySubscribedLink>
      </Content>
    </>
  );
};

interface ImageScrollerProps {
  imageUrls: string[];
  forwards: boolean;
}

const ImageScroller = ({imageUrls, forwards}: ImageScrollerProps) => {
  return (
    <ImageScrollerContainer forwards={forwards}>
      {imageUrls.map((url) => (
        <ScrollingImage key={url} src={url} width={125} />
      ))}
    </ImageScrollerContainer>
  );
};

interface ImageScrollerContainerProps {
  forwards: boolean;
}

const ImageScrollerContainer = styled.div<ImageScrollerContainerProps>`
  display: flex;
  width: 5000px;
  animation: linear infinite
    ${(props) => (props.forwards ? 'alternate' : 'alternate-reverse')} slidein;
  animation-name: run;
  animation-duration: 35s;

  @keyframes run {
    0% {
      transform: translateX(-500px);
    }
    100% {
      transform: translateX(-250px);
    }
  }
`;

const ScrollingImage = styled.img`
  margin-top: 5px;
  margin-left: 5px;
  border-radius: 10px;
`;

const monthlyId = '6457ed73-e1ba-420f-a6bf-0cf0c263863c';

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: -1;
`;

const backgroundColour = '#282828';

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 250px;
  background: linear-gradient(
    180deg,
    rgba(36, 36, 36, 0.34) 0%,
    rgba(26, 26, 26, 0.579941) 30%,
    ${backgroundColour} 70%
  );
`;

const PlanSelectorContainer = styled.div`
  transform: scale(0.5);
  margin: -30px 0 -35px 0;
`;

const PlansPageStyles = createGlobalStyle`
  body {
    background-color: ${backgroundColour};
  }

  color: ${Colours.white};
`;

const PremiumItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 85px;
`;

const FeatureDescription = styled.p`
  color: ${Colours.white};
  font-size: 0.8rem;
  text-align: center;
  margin-top: 4px;
`;

const PremiumFeatures = styled.div`
  max-width: 400px;
  margin: auto;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  justify-items: center;

  margin-top: 10px;
`;

const Titles = styled.div`
  display: flex;

  justify-content: center;
  align-items: baseline;

  margin-top: 160px;
`;

const Studio = styled.h1`
  font-weight: 600;
  font-size: 30px;
  text-align: right;
  color: ${Colours.white};
  margin-right: 0.6rem;
`;

const Premium = styled.h1`
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 0.02em;

  color: ${Colours.subscriberPurple};

  text-transform: uppercase;
`;

const Subtitle = styled.h2`
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;

  color: ${Colours.white};
  text-align: center;
`;

const Content = styled.main``;

const StartTrialButton = styled.button`
  width: 513px;
  height: 43px;

  background: ${Colours.subscriberPurple};
  border-radius: 1px;

  color: ${Colours.white};
  margin: auto;

  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;

  display: block;

  padding: 0;
  border: none;
`;

const AlreadySubscribedLink = styled.a`
  display: block;
  font-weight: 500;
  font-size: 13px;
  line-height: 12px;
  text-align: center;

  color: rgba(255, 255, 255, 0.5);
  margin-top: 10px;

  border: none;

  &:hover {
    border: none;
  }
`;

const Cross = styled.button`
  color: ${Colours.white};

  position: absolute;
  right: 10px;
  top: 0;

  font-size: 4rem;

  border: none;
  background: none;
`;

export default Plans;
