import styled, {css} from 'styled-components';
import Colours from '../../constants/colours';

export const Wrapper = styled.div`
  display: flex;
  flex: 1 1 50%;
  align-items: stretch;
  justify-content: center;
  max-width: 600px;
  margin: auto;
  min-height: 200px;
  overflow: none;

  > div:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  > div:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  @media (max-width: 900px) {
    transform: none;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 2rem 0;
  }
`;

interface PlanProps {
  selected?: boolean;
}

export const Plan = styled.div<PlanProps>`
  ${({selected = false}) => css`
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-bottom: 20px;
    ${selected ? Colours.subscriberPurple : Colours.white};

    > div:last-child {
      background-color: ${selected ? Colours.white : Colours.grey[200]};
      border-top: 10px solid
        ${selected ? Colours.subscriberPurple : Colours.grey[200]};
    }
  `}
`;

export const Term = styled.div`
  font-weight: bold;
  font-size: 1.2rem;
  text-transform: uppercase;
  margin-bottom: 1.2rem;
`;

export const CurrencySymbol = styled.span`
  color: ${Colours.grey[600]};
  font-size: 2rem;
  grid-row: 1 / 3;
  text-align: right;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  cursor: pointer;
  padding-top: 10px;
`;

export const PriceWrapper = styled.div`
  display: grid;
  grid-template-columns: 15px 1fr 1fr;
  grid-template-rows: 30px 1fr;
  justify-items: end;
  max-width: 50%;
`;

export const Price = styled.span`
  font-weight: bold;
  font-size: 6rem;
  grid-row: 1/3;
  transform: translateY(-8px);
`;

export const FractionalPrice = styled.div`
  justify-self: center;
  font-size: 2rem;
`;

export const PriceTerm = styled.span`
  font-size: 1.5rem;
  justify-self: center;
  color: ${Colours.grey[600]};
`;

export const SavingsBanner = styled.div`
  position: absolute;
  top: 30px;
  right: -40px;
  padding: 0.5rem 4rem;
  color: ${Colours.white};
  font-weight: bold;
  background-color: ${Colours.subscriberPurple};
  transform: rotate(45deg);
`;
