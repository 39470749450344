import React, {FunctionComponent, useState} from 'react';
import {PlanBillingType} from '../../types';
import {
  Wrapper,
  Plan,
  Info,
  Term,
  PriceWrapper,
  CurrencySymbol,
  Price,
  PriceTerm,
  SavingsBanner,
  FractionalPrice,
} from './PlanSelector.styles';

interface PlanSelectorProps {
  pricing: {
    monthly: {
      price: string;
      currencySymbol: string;
      term: string;
    };
  };
  onSelectPlanType: (plan: PlanBillingType) => void;
}

export const PlanSelector: FunctionComponent<PlanSelectorProps> = ({
  pricing,
  onSelectPlanType,
}) => {
  const [selected, setSelected] = useState<PlanBillingType>('monthly');

  const handleSelectPlanType = (plan: PlanBillingType) => {
    setSelected(plan);
    onSelectPlanType(plan);
  };

  const monthlyPrice = pricing.monthly.price.split('.');

  return (
    <Wrapper>
      <Plan
        data-test-id="select-monthly"
        selected={selected === 'monthly'}
        onClick={() => handleSelectPlanType('monthly')}
      >
        <Info>
          <Term>MONTHLY</Term>
          <PriceWrapper>
            <CurrencySymbol>{pricing.monthly.currencySymbol}</CurrencySymbol>
            <Price>{monthlyPrice[0]}</Price>
            <FractionalPrice>{monthlyPrice[1]}</FractionalPrice>
            <PriceTerm>/{pricing.monthly.term}</PriceTerm>
          </PriceWrapper>
        </Info>
      </Plan>
    </Wrapper>
  );
};
