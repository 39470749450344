import React from 'react';
import {PremiumIcon} from './PremiumSection.styles';

interface Feature {
  headline: string;
  details: string;
  shortDescription: string;
  icon: (width?: string) => React.SVGProps<SVGSVGElement>;
}

export const features = (): Feature[] => {
  return [
    {
      headline: 'Unlimited access',
      details:
        'to over 12,000 royalty free sounds, plus new ones added every week',
      shortDescription: 'Unlimited access to hundreds of soundpacks',
      icon: (width?: string) => (
        <PremiumIcon width={width}>
          <svg
            width="50%"
            viewBox="0 0 101 92"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M46.3457 21.0868V9.66427C46.3457 8.05417 45.0394 7.02128 43.4901 7.32507L27.8752 10.7275C25.9309 11.1528 24.8677 12.2161 24.8677 13.9173L24.9284 47.699C25.0803 49.1875 24.3816 50.1597 23.0449 50.4331L18.2146 51.4356C12.1388 52.7115 9.28315 55.8102 9.28315 60.3974C9.28315 65.0455 12.8679 68.296 17.9108 68.296C22.3766 68.296 29.06 65.0151 29.06 56.1747V28.3778C29.06 26.7677 29.3638 26.4336 30.7916 26.1298L44.6749 23.0918C45.7077 22.8792 46.3457 22.0893 46.3457 21.0868Z"
              fill="#F2F2F2"
            />
            <path
              d="M97.3667 21.1045L57.1418 31.9611C55.9189 32.2952 55.0766 33.3606 55.0766 34.572V71.3659C53.3955 70.4309 51.4672 69.8561 49.3844 69.8561C43.1057 69.8561 38 74.7251 38 80.7127C38 86.7003 43.1057 91.5693 49.3844 91.5693C55.6631 91.5693 60.7688 86.7003 60.7688 80.7127C60.7688 80.0939 60.7688 47.4769 60.7688 47.4769L95.3016 38.1711V60.5092C93.6205 59.5743 91.6922 58.9994 89.6094 58.9994C83.3307 58.9994 78.225 63.8684 78.225 69.8561C78.225 75.8437 83.3307 80.7127 89.6094 80.7127C95.8881 80.7127 100.994 75.8437 100.994 69.8561C100.994 69.2372 100.994 23.7153 100.994 23.7153C100.994 21.9202 99.1932 20.6125 97.3667 21.1045Z"
              fill="#F2F2F2"
            />
          </svg>
        </PremiumIcon>
      ),
    },

    {
      headline: 'Take your track further',
      details: 'with up to 64 sections and 32 channels',
      shortDescription: 'Add up to 64 sections and 32 channels',
      icon: (width?: string) => (
        <PremiumIcon width={width}>
          <svg
            width="36%"
            viewBox="0 0 72 66"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.3129 35.257C27.8688 35.257 35.889 27.271 35.889 17.6468C35.889 8.02261 27.9712 0.0707233 18.3129 0.0707233C8.68873 0.0707233 0.736842 8.02261 0.736842 17.6468C0.736842 27.3392 8.68873 35.257 18.3129 35.257ZM43.9774 11.4696H68.1743C69.8808 11.4696 71.2459 10.1386 71.2459 8.43215C71.2459 6.75987 69.8808 5.39474 68.1743 5.39474H43.9774C42.3051 5.39474 40.94 6.75987 40.94 8.43215C40.94 10.1386 42.3051 11.4696 43.9774 11.4696ZM18.3129 29.148C17.016 29.148 15.8557 28.1924 15.8557 26.7932V20.0016H9.50781C8.24507 20.0016 7.18709 18.9095 7.15296 17.6468C7.15296 16.384 8.24507 15.2919 9.50781 15.2919H15.8557V8.50041C15.8557 7.13528 17.016 6.17969 18.3129 6.17969C19.6439 6.17969 20.7701 7.13528 20.7701 8.50041V15.2919H27.118C28.4149 15.2919 29.4729 16.384 29.4729 17.6468C29.4729 18.9095 28.4149 20.0016 27.118 20.0016H20.7701V26.7932C20.7701 28.1924 19.6439 29.148 18.3129 29.148ZM43.9774 29.3528H68.1743C69.8808 29.3528 71.2459 28.0218 71.2459 26.3154C71.2459 24.609 69.8808 23.278 68.1743 23.278H43.9774C42.3051 23.278 40.94 24.609 40.94 26.3154C40.94 28.0218 42.3051 29.3528 43.9774 29.3528ZM3.74013 47.236H68.1743C69.8808 47.236 71.2459 45.905 71.2459 44.1986C71.2459 42.4922 69.8808 41.1612 68.1743 41.1612H3.74013C2.03372 41.1612 0.702714 42.4922 0.702714 44.1986C0.702714 45.905 2.03372 47.236 3.74013 47.236ZM3.74013 65.1192H68.1743C69.8808 65.1192 71.2459 63.7541 71.2459 62.0818C71.2459 60.3754 69.8808 59.0444 68.1743 59.0444H3.74013C2.03372 59.0444 0.702714 60.3754 0.702714 62.0818C0.702714 63.7541 2.03372 65.1192 3.74013 65.1192Z"
              fill="#F2F2F2"
            />
          </svg>
        </PremiumIcon>
      ),
    },

    {
      headline: 'Audio import',
      details: 'by easily dropping your own sounds into your project',
      shortDescription: 'Import your own sounds into your project',
      icon: (width?: string) => (
        <PremiumIcon width={width}>
          <svg
            width="37%"
            viewBox="0 0 74 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M50.875 60.125H9.25V18.5H13.875V9.25H4.625C2.072 9.25 0 11.322 0 13.875V64.75C0 67.3076 2.072 69.375 4.625 69.375H55.5C58.0576 69.375 60.125 67.3076 60.125 64.75V37.1434H50.875V60.125Z"
              fill="#F2F2F2"
            />
            <path
              d="M19.425 18.963L37.925 5.08802C38.6187 4.56077 39.5622 4.47752 40.3439 4.87064C41.1301 5.26377 41.625 6.06389 41.625 6.93802V13.8755H48.5625C62.5901 13.8755 74 25.2854 74 39.313C74 40.386 73.26 41.3203 72.2194 41.5654C72.0436 41.607 71.8633 41.6255 71.6875 41.6255C70.8273 41.6255 70.0179 41.1445 69.6201 40.349L69.0744 39.2621C65.527 32.1628 58.3952 27.7505 50.4587 27.7505H41.625V34.688C41.625 35.5621 41.1301 36.3623 40.3485 36.7554C39.5669 37.1485 38.6234 37.0653 37.925 36.538L19.425 22.663C18.8422 22.2283 18.5 21.5391 18.5 20.813C18.5 20.0869 18.8422 19.3978 19.425 18.963Z"
              fill="#F2F2F2"
            />
          </svg>
        </PremiumIcon>
      ),
    },

    {
      headline: 'Unlock pro features',
      details: 'on Launchpad for iOS',
      shortDescription: 'Unlock pro features on Launchpad for iOS',
      icon: (width?: string) => (
        <PremiumIcon width={width}>
          <svg
            width="41%"
            viewBox="0 0 82 78"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.82471 77.9722H45.787C50.931 77.9722 53.4432 75.4201 53.4432 69.8374V40.5682C53.4432 35.7033 51.4893 33.1113 47.5415 32.5531V21.627C47.5415 11.618 53.9616 6.31445 61.4185 6.31445C68.8753 6.31445 75.2555 11.618 75.2555 21.627V30.4795C75.2555 32.9119 76.6911 34.1082 78.5254 34.1082C80.28 34.1082 81.6756 33.0316 81.6756 30.4795V22.4644C81.6756 7.43099 71.8262 0.173498 61.4185 0.173498C50.9709 0.173498 41.1214 7.43099 41.1214 22.4644V32.4733L8.62223 32.5531C3.47819 32.5531 0.168458 35.0653 0.168458 40.5682V69.8374C0.168458 75.4201 2.68066 77.9722 7.82471 77.9722Z"
              fill="#F2F2F2"
            />
          </svg>
        </PremiumIcon>
      ),
    },
  ];
};
